<template>
  <div class="page-singer-pool" v-loading="loading">
    <div class="singer-pool-header">
      <p class="singer-pool-text">{{$t('h.singerPool.singerPool')}}</p>
      <div v-if="!deletePool" class="operate-btn">
        <div @click="deletePool = true">
          <i class="icon-trash"></i>
          <span>{{$t('h.singerPool.delete')}}</span>
        </div>
        <div @click="addSingerPool">
          <i class="icon-new"></i>
          <span>{{$t('h.singerPool.create')}}</span>
        </div>
      </div>
      <div v-else class="cancel-btn" @click="deletePool = false">{{$t('h.singerPool.cancel')}}</div>
    </div>

    <tabs
      ref="tabs"
      v-model="activeName"
      @shared="changeShared"
      :show-delete-btn="deletePool"
    >
    </tabs>

    <div style="flex: 1; overflow: auto">
      <div class="singer-list">
        <div v-if="!isShared" class="singer-item add-singer" @click="addSinger">
          <i class="icon-new pr5"></i>
          <span>{{$t('h.singerPool.addSinger')}}</span>
        </div>
        <div
          class="singer-item singer-card"
          v-for="singer in tempList"
          :key="singer.f_singer_id"
          @mouseenter="showDeleteBtn(singer.f_singer_id)"
          @mouseleave="activeId = 0"
        >
          <i
            v-if="!isShared"
            v-show="activeId === singer.f_singer_id"
            class="el-icon-close close-btn"
            @click="deleteSinger(singer.f_singer_id)"
          ></i>
          <img src="~assets/default.png" />
          <span
            class="singer-name limit-two-line"
            :title="singer.singer_name"
            >{{ singer.singer_name }}</span
          >
          <i
            :class="
              mp3Url === singer.audio && isAudioPlay
                ? 'icon-pause2'
                : 'icon-play2'
            "
            @click="playMusic(singer)"
          ></i>
        </div>
      </div>
    </div>

    <div class="page-bottom">
      <audio-player 
        class="audio-player" 
        ref="audioPlayer" 
        :mp3-name="mp3Name" 
        :mp3-url="mp3Url" 
        @updateAudioStatus="updateAudioStatus"
      >
      </audio-player>

      <my-pagination
        class="pr10"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="singerList.length"
        @change-page="changePage"
      >
      </my-pagination>
    </div>

    <!-- 删除歌手弹框 -->
    <el-dialog :visible.sync="showDeleteDialog" top="40vh" width="20%" center>
      <p class="tac fs16 fw600">{{$t('h.singerPool.delmsg')}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="showDeleteDialog = false">{{$t('h.singerPool.cancel')}}</el-button>
        <el-button round type="primary" @click="confirmDeleteSinger"
          >{{$t('h.singerPool.confirm')}}</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增弹框 -->
    <el-dialog
      :visible.sync="showAddDialog"
      :title="addType === 'pool' ? $t('h.singerPool.title1') : $t('h.singerPool.title2')"
      top="20vh"
      width="50%"
      :before-close="initDialog"
      :destroy-on-close="true"
      class="add-dialog"
      center
    >
      <div class="upload-bar" v-loading="loading">
        <div class="pool-name" v-if="addType === 'pool'">
          <span>{{$t('h.singerPool.singerPoolName')}}</span>
          <el-input v-model="poolName" :placeholder="$t('h.singerPool.singerPoolNameMsg')"></el-input>
        </div>

        <div class="upload-btn">
          <!-- <el-upload
            drag
            action=''
            :auto-upload="false"
            :limit="1"
            :on-change="uploadfile"
            :file-list="fileList"
            :show-file-list="true"
          >
            <img
              class="upload-icon"
              src="~assets/dafen_shangchuan_icon@2x.png"
              alt=''
            />
            <div class="el-upload__text">
              zip压缩包文件格式：内部为多首以{歌手名-歌曲名.mp3}命名的歌曲文件(请以zip格式打包)
            </div>
          </el-upload> -->
          <div class="pool-name">
            <span>{{$t('h.singerPool.singerUrl')}}</span>
            <el-input
              class="upload-url"
              v-model="singerUrl"
              type="textarea"
              rows="8"
              :placeholder="$t('h.singerPool.singerUrlMsg')"
            ></el-input>
          </div>
          <div class="tips">
            {{$t('h.singerPool.tips')}}
            <a
              target="blank"
              href="https://y.qq.com/n/ryqq/singer/0025NhlN2yWrP4"
            >
              https://y.qq.com/n/ryqq/singer/
            </a>
            {{$t('h.singerPool.tips2')}}
          </div>
        </div>
      </div>
      <span v-if="!loading"  slot="footer" class="dialog-footer">
        <el-button
          v-if="addType === 'pool'"
          round
          type="primary"
          @click="confirmAddPool"
          >{{$t('h.singerPool.submit')}}</el-button
        >
        <el-button v-else round type="primary" @click="confirmAddSinger"
          >{{$t('h.singerPool.submit')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Tabs from "./__tabs.vue";
export default {
  components: {
    Tabs,
  },

  data() {
    return {
      mp3Url: '',
      mp3Name: '',
      isAudioPlay: false,
      loading: false,
      deletePool: false,
      activeName: '',
      singerList: [],
      activeId: 0,
      showDeleteDialog: false,
      // 将要删除的歌手id
      deleteSingerId: 0,
      showAddDialog: false,
      // 新增类型
      addType: "pool",
      // 新增歌手池名
      poolName: '',
      mIDList: '',
      singerUrl: '',
      progressStatus: false,
      fileName: '',
      progressSpeed: 10,
      fileList: [],
      currentPage: 0,
      pageSize: 16,
      tempList: [],
      isShared: false
    }
  },

  watch: {
    activeName(val) {
      // 重新获取歌手列表
      this.getSingers(val);
    },
  },

  // 切换路由前，停止播放歌曲
  beforeRouteLeave (to, from, next) {
    this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
    setTimeout(() => {
      next()
    });
  },

  methods: {
    initDialog() {
      this.fileList = [];
      this.singerUrl = '';
      this.poolName = '';
      this.showAddDialog = false;
    },
    changeShared(val) {
      this.isShared = val;
    },
    uploadfile(file) {
      let reg = /\.(zip)$/;
      if (reg.test(file.name)) {
        this.fileList[0] = file;
      } else {
        this.$message({
          message: "文件格式不支持，请按要求上传文件",
          type: "error",
          offset: 120,
        });
        this.fileList = [];
      }
    },
    async getSingers(id) {
      this.loading = true;
      const res = await this.$store.dispatch("getSingerList", {
        singer_pool_id: id,
        token: localStorage.getItem("token"),
      });
      this.loading = false;
      if (res.success) {
        this.singerList = res.singers;
        this.currentPage = 1;
        this.changeCurrentPage(this.singerList, this.currentPage);
        const singer = this.tempList[0]
        this.mp3Url = singer.audio
        this.mp3Name = singer.song_name + ' - ' + singer.singer_name
        setTimeout(() => {
          this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
        }, 200)
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
    },

    playMusic(singer) {
      if (singer.audio !== this.mp3Url) {
        this.mp3Name = singer.song_name + ' - ' + singer.singer_name
        this.mp3Url = singer.audio
        this.isAudioPlay = true
      } else {
        if (this.isAudioPlay) {
          this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
          this.isAudioPlay = false
        } else {
          this.$refs.audioPlayer && this.$refs.audioPlayer.playMusic()
          this.isAudioPlay = true
        }
      }
    },

    updateAudioStatus (status) {
      this.isAudioPlay = status
    },

    changeCurrentPage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tempList.push(list[from]);
        }
      }
    },

    changePage(page) {
      this.currentPage = +page;
      this.changeCurrentPage(this.singerList, this.currentPage);
    },

    showDeleteBtn(id = 0) {
      if (id !== 0) {
        this.activeId = id;
      }
    },

    addSinger() {
      this.addType = "singer";
      this.showAddDialog = true;
    },

    addSingerPool() {
      this.addType = "pool";
      this.showAddDialog = true;
    },

    deleteSinger(id) {
      this.deleteSingerId = id;
      this.showDeleteDialog = true;
    },

    async confirmDeleteSinger() {
      this.loading = true;
      const res = await this.$store.dispatch("delSinger", {
        f_singer_id: this.deleteSingerId,
        token: localStorage.getItem("token"),
      });
      if (res.success) {
        this.showDeleteDialog = false;
        this.getSingers(this.activeName);
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
      this.loading = false;
    },
    splitUrl() {
      try {
        const list = this.singerUrl.trim().split(/\s+|,|，/);
        let str = '';
        list.forEach((item) => {
          let mid = item.match(/singer\W(\S*)/)[1];
          str += mid += ",";
        });
        str = str.substring(0, str.length - 1);
        this.mIDList = str;
        return true;
      } catch (err) {
        this.$message({
          message: "歌手链接格式错误 请检查",
          type: "error",
          offset: 120,
        });
        return false;
      }
    },
    async confirmAddSinger() {
      if (!this.singerUrl) {
        this.$message({
          message: "请先输入歌手链接",
          type: "error",
          offset: 120,
        });
        return;
      }
      if (!this.splitUrl()) {
        return;
      }
      // 调用新增歌手接口
      let fd = new FormData();
      fd.append("singer_pool_id", this.activeName);
      fd.append("token", localStorage.getItem("token"));
      // fd.append("file", this.fileList[0].raw);
      fd.append("singer_link", this.mIDList);
      this.loading = true;
      const res = await this.$store.dispatch("addSinger", fd);
      if (res.success) {
        this.$message({
          message: res.msg,
          type: "success",
          offset: 120,
        });
        this.initDialog();
        this.getSingers(this.activeName);
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
      this.loading = false;
    },

    async confirmAddPool() {
      if (!this.poolName) {
        this.$message({
          message: "请先输入歌手池名",
          type: "error",
          offset: 120,
        });
        return;
      }
      // else if (!this.singerUrl) {
      //   this.$message({
      //     message: "请先输入歌手链接",
      //     type: "error",
      //     offset: 120,
      //   });
      //   return;
      // }
      // else if (!this.fileList[0]) {
      //   this.$message({
      //     message: "请先上传文件",
      //     type: "error",
      //     offset: 120,
      //   });
      //   return;
      // }
      if (!this.splitUrl()) {
        return;
      }
      let fd = new FormData();
      fd.append("singer_pool_name", this.poolName);
      fd.append("token", localStorage.getItem("token"));
      // fd.append("file", this.fileList[0].raw);
      fd.append("singer_link", this.mIDList);
      this.loading = true;
      const res = await this.$store.dispatch("creatSingerPool", fd);
      if (res.success) {
        this.$message({
          message: "成功新增歌手池",
          type: "success",
          offset: 120,
        });
        // 成功后获取最新歌手池列表
        this.$refs.tabs.updatePoolList();
        this.initDialog();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.page-singer-pool {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  audio {
    display: none;
  }

  .el-tabs__item {
    color: #9b9b9b;
  }

  .el-tabs__item.is-active {
    color: #ff8c36;
  }

  .el-tabs__active-bar {
    height: 3px;
    border-radius: 2px;
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }

  .singer-pool-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .singer-pool-text {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
  }

  .operate-btn {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    color: #ff8c36;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 30px;
      cursor: pointer;
    }

    img {
      width: 32px;
      height: 32px;
    }

    i {
      font-size: 20px;
      padding-right: 5px;
    }
  }

  .cancel-btn {
    width: 128px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid #ff8c36;
    color: #ff8c36;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
  }

  .singer-list {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }

  .singer-item {
    position: relative;
    background: #F5F6FA;
    width: 20%;
    border-radius: 6px;
    height: 96px;
    line-height: 96px;
    padding: 0 20px;
    margin: 0 22px 22px 0;
    color: #1a1a1a;
    font-size: 16px;
  }

  .singer-item:nth-of-type(4n) {
    margin-right: 0;
  }

  .singer-item:hover {
    box-shadow: 0px 2px 8px 0px rgba(26, 26, 26, 0.2);
  }

  .singer-card {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    .singer-name {
      flex: 1;
      padding: 0 20px 0 10px;
      color: #747474;
    }

    .icon-pause2, .icon-play2 {
      cursor: pointer;
      color: #ff8c36;
      font-size: 22px;
    }
  }

  .add-singer {
    text-align: center;
    color: #adadad;
    cursor: pointer;
  }

  .close-btn {
    position: absolute;
    top: 9px;
    right: 9px;
    color: #adadad;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  .dialog-footer {
    .el-button {
      padding: 10px 40px;
    }
  }

  .page-bottom {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .audio-player {
    margin: 0 50px 0 125px;
  }
}

.add-dialog {
  border-radius: 8px;

  .pool-name {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 500px;

    span {
      width: 100px;
    }
  }

  .upload-icon {
    width: 60px;
    height: 40px;
    margin: 0 auto;
  }

  .upload-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .upload-btn {
    .el-upload {
      width: 100%;
    }

    .el-upload-dragger {
      padding: 40px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .upload-dragger {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 524px;
    height: 200px;
    border-radius: 6px;
    /* opacity: 0.2; */
    border: 1px solid #e8e8e8;
  }

  .el-dialog__body {
    padding: 20px 80px;
  }

  .el-dialog__header {
    font-weight: 600;
    background: #f5f6fa;
    border-radius: 8px 8px 0 0;
  }

  .close-icon {
    margin-top: 5px;
    width: 16px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: #fff;
    border-radius: 16px;
    background: #d6d6da;
    cursor: pointer;
  }

  .tips {
    font-weight: 300;
    color: #B7B7B8;
    font-size: 12px;
    margin: 15px 10px;
    user-select: text;

    a {
      color: #999;
      text-decoration: underline;
    }
  }

  .upload__text {
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #8b8888;
  }

  ::v-deep .el-upload-dragger {
    width: 524px;
    height: 200px;
    border-radius: 6px;
    /* opacity: 0.2; */
    border: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  ::v-deep .el-upload__text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #8b8888;
  }

  ::v-deep .el-upload-dragger .el-upload__text em {
    color: #8b8888;
    text-decoration: underline;
  }

  ::v-deep .upload-icon {
    width: 56px;
    height: 37px;
    /* margin-bottom: 40px; */
  }
}
</style>
