
<template>
  <div class="tabs">
    <div
      ref="navWrap"
      class="tabs-nav-wrap"
      :class="[scrollable ? 'tabs-nav-scrollable' : '']"
    >
      <!-- 左箭头 -->
      <i
        class="icon-left tabs-nav-prev"
        :class="[
          scrollable ? '' : 'tabs-nav-scroll-disabled',
          prevDisabled ? 'not-allowed' : '',
        ]"
        @click="scrollPrev"
      ></i>
      <!-- 右箭头 -->
      <i
        class="icon-right tabs-nav-next"
        :class="[
          scrollable ? '' : 'tabs-nav-scroll-disabled',
          nextDisabled ? 'not-allowed' : '',
        ]"
        @click="scrollNext"
      ></i>
      <!-- tabs-content -->
      <div ref="navScroll" class="tabs-nav-scroll">
        <div ref="nav" class="tabs-nav" :style="navStyle">
          <div class="tabs-tab" v-for="(item, index) in tabList" :key="index">
            <span
              @click="handleChange(index)"
              :class="item.name === activeKey ? 'is-active' : ''"
              >{{ item.label }}</span
            >
            <i
              v-if="!item.is_shared"
              v-show="showDeleteBtn"
              @click="deleteTab(item.name)"
              class="icon-close"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除弹框 -->
    <el-dialog :visible.sync="showDeleteDialog" top="40vh" width="20%" center>
      <p class="tac fs16 fw600">{{$t('h.singerPool.delmsg2')}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="showDeleteDialog = false">{{$t('h.singerPool.cancel')}}</el-button>
        <el-button round type="primary" @click="deleteSingerPool"
          >{{$t('h.singerPool.confirm')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 参考 https://juejin.cn/post/6844904035019915271

import elementResizeDetectorMaker from "element-resize-detector";

export default {
  name: "Tabs",

  props: {
    value: {
      type: [String, Number],
    },
    showDeleteBtn: {
      type: Boolean,
    },
  },

  data() {
    return {
      activeKey: this.value,
      scrollable: false,
      navStyle: {
        transform: "",
      },
      is_shared: "",
      prevDisabled: true,
      nextDisabled: false,
      tabList: [],
      showDeleteDialog: false,
      deletePoolId: "",
    };
  },

  watch: {
    value(val) {
      this.activeKey = val;
    },

    activeKey(val) {
      this.$emit("input", val);
    },
    is_shared(val) {
      this.$emit("shared", val);
    },
    showDeleteBtn() {
      this.$nextTick(() => {
        this.handleResize();
        this.canScroll();
      });
    },
  },

  mounted() {
    // 初始化
    this.init();
  },

  beforeDestroy() {
    this.observer.removeListener(this.$refs.navWrap, this.handleResize);
  },

  methods: {
    async init() {
      // 监听vue中元素大小变化
      await this.getSingerPool();
      this.observer = elementResizeDetectorMaker();
      this.observer.listenTo(this.$refs.navWrap, this.handleResize);
    },

    async getSingerPool() {
      const res = await this.$store.dispatch("getSingerPool", {
        token: localStorage.getItem("token"),
      });
      if (res.success) {
        const list = res.singer_pools;
        const tabList = [];
        list.forEach((item) => {
          tabList.push({
            label: item.singer_pool_name,
            name: item.singer_pool_id,
            is_shared: item.is_shared,
          });
        });
        this.tabList = tabList;
        this.activeKey = res.singer_pools[0].singer_pool_id;
        this.is_shared = res.singer_pools[0].is_shared;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
    },

    handleChange(index) {
      const nav = this.tabList[index];
      this.activeKey = nav.name;
      this.is_shared = nav.is_shared;
    },

    async updatePoolList() {
      await this.getSingerPool();
      this.$nextTick(() => {
        // 删除了歌手池 等DOM更新之后再一次初始化tabs组件，计算是否要滚动
        this.handleResize();
        this.canScroll();
      });
    },

    deleteTab(name) {
      this.deletePoolId = name;
      this.showDeleteDialog = true;
    },

    async deleteSingerPool() {
      this.showDeleteDialog = false;

      const res = await this.$store.dispatch("delSingerPool", {
        singer_pool_id: this.deletePoolId,
        token: localStorage.getItem("token"),
      });
      if (res.success) {
        // 获取最新歌手池列表
        await this.updatePoolList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120,
        })
      }
    },

    // 判断是否内容过多需要滚动
    handleResize() {
      const navWidth = this.$refs.nav.offsetWidth;
      const scrollWidth = this.$refs.navScroll.offsetWidth;
      if (scrollWidth < navWidth) {
        this.scrollable = true;
      } else {
        this.scrollable = false;
      }
      this.updateMove();
    },

    updateMove() {
      const navWidth = this.$refs.nav.offsetWidth;
      const scrollWidth = this.$refs.navScroll.offsetWidth;
      const currentOffset = this.getCurrentScrollOffset();
      if (scrollWidth < navWidth) {
        if (navWidth - currentOffset < scrollWidth) {
          this.navStyle.transform = `translateX(-${navWidth - scrollWidth}px)`;
        }
      } else {
        if (currentOffset > 0) {
          this.navStyle.transform = `translateX(-${0}px)`;
        }
      }
    },

    // 获取当前navStyle元素transformX的移动距离
    getCurrentScrollOffset() {
      const { navStyle } = this;
      const reg = /translateX\(-(\d+(\.\d+)*)px\)/;
      return navStyle.transform ? Number(navStyle.transform.match(reg)[1]) : 0;
    },

    scrollPrev() {
      const containerWidth = this.$refs.navScroll.offsetWidth;
      const currentOffset = this.getCurrentScrollOffset();
      this.canScroll();
      if (!currentOffset) return;
      let newOffset = 0;
      if (currentOffset > containerWidth) {
        newOffset = currentOffset - containerWidth;
      }
      this.navStyle.transform = `translateX(-${newOffset}px)`;
    },

    scrollNext() {
      // 实际内容的宽度
      const navWidth = this.$refs.nav.offsetWidth;
      // 当前tab显示的宽度
      const containerWidth = this.$refs.navScroll.offsetWidth;
      // 当前navStyle元素移动的距离
      const currentOffset = this.getCurrentScrollOffset();
      // 赶在return前计算一下前后箭头是否能滚动、是否禁用
      this.canScroll();
      // 实际内容的宽度 - 当前navStyle元素的移动距离 <= 当前页面宽度
      if (navWidth - currentOffset <= containerWidth) return;
      let newOffset = null;
      // 实际内容的宽度 - 当前navStyle元素的移动距离 > 当前页面宽度 * 2
      if (navWidth - currentOffset > containerWidth * 2) {
        newOffset = currentOffset + containerWidth;
      } else {
        newOffset = navWidth - containerWidth;
      }
      this.navStyle.transform = `translateX(-${newOffset}px)`;
    },

    // 判断左右箭头是否禁用（是否翻到底）
    canScroll() {
      this.$nextTick(() => {
        const containerWidth = this.$refs.navScroll.offsetWidth;
        const currentOffset = this.getCurrentScrollOffset();
        if (!currentOffset) {
          this.prevDisabled = true;
        } else {
          this.prevDisabled = false;
        }

        const navWidth = this.$refs.nav.offsetWidth;
        if (navWidth - currentOffset <= containerWidth) {
          this.nextDisabled = true;
        } else {
          this.nextDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabs {
  .tabs-nav-wrap {
    position: relative;
    margin-bottom: 24px;
  }

  .tabs-tab {
    position: relative;
    display: inline-block;
    margin-right: 32px;
    padding: 8px 0;
    cursor: pointer;
    font-size: 16px;
  }

  .tabs-inv-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ff8c36;
    height: 3px;
    border-radius: 2px;
    transition: transform 300ms ease-in-out;
  }

  .tabs-nav-scroll {
    overflow: hidden;
    white-space: nowrap;
  }

  .tabs-nav {
    position: relative;
    float: left;
    transition: transform 0.5s ease-in-out;
  }

  .tabs-nav-prev, .tabs-nav-next {
    position: absolute;
    width: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: #747474;
    top: 2px;
  }

  .tabs-nav-prev {
    left: 0;
  }

  .tabs-nav-next {
    right: 0;
  }

  .tabs-nav-scrollable {
    padding: 0 32px;
  }

  .tabs-nav-scroll-disabled {
    display: none;
  }

  .not-allowed {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .icon-close {
    padding: 2px 5px 0 5px;
    color: #adadad;
  }

  .is-active {
    color: #ff8c36;
    padding-bottom: 3px;
    border-bottom: 2px solid #ff8c36;
  }
}
</style>
